import React, { useState } from "react";
import Head from "next/head";
import {
  Button,
  ConfigProvider,
  Drawer,
  Layout,
  Menu,
  MenuProps,
} from "antd";
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";

const { Content, Sider } = Layout;

//const sidebarBackgroundColor = "#343351";
//const sidebarMenuSelectedItemBackgroundColor = "#2A2939";

const DefaultLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const router = useRouter();
  // menu.key must match the router.pathname, see example below: "/dashboard"
  const [selected, setSelected] = useState<string>();

  // key must also be unique, for obvious reason
  function getMenu(): MenuProps["items"] {
    const menu: MenuProps["items"] = [];

    menu.push({
      key: "/",
      label: "Overview",
      style: {
        background: (selected === "/" ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
        color: (selected === "/" ? "white" : "grey"),
      },
      onClick: () => router.push("/"),
      disabled: true,
    });

    menu.push(
      {
        key: "#menu-1",
        label: "Group Management",
        style: {
          background: ((selected === "/hobby" || selected === "/sig") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
          color: "grey",
        },
        disabled: true,
        children: [
          {
            key: "/hobby",
            label: "Hobby",
            style: {
              background: ((selected === "/hobby" || selected === "/sig") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
              color: ((selected === "/hobby") ? "white" : "grey"),
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
          {
            key: "/sig",
            label: "SIG",
            style: {
              background: ((selected === "/hobby" || selected === "/sig") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
              color: ((selected === "/sig") ? "white" : "grey"),
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
        ],
      },
      {
        key: "#menu-2",
        label: "Group Member List",
        style: {
          background: ((selected === "/hobby-membership" || selected === "/class-membership" || selected === "/sig-membership") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
          color: "grey",
        },
        disabled: true,
        children: [
          {
            key: "/hobby-membership",
            label: "Hobby Membership",
            style: {
              background: ((selected === "/hobby-membership" || selected === "/class-membership" || selected === "/sig-membership") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
              color: ((selected === "/hobby-membership") ? "white" : "grey"),
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
          {
            key: "/class-membership",
            label: "Class Membership",
            style: {
              background: ((selected === "/hobby-membership" || selected === "/class-membership" || selected === "/sig-membership") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
              color: ((selected === "/class-membership") ? "white" : "grey"),
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
          {
            key: "/sig-membership",
            label: "SIG Membership",
            style: {
              background: ((selected === "/hobby-membership" || selected === "/class-membership" || selected === "/sig-membership") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
              color: ((selected === "/sig-membership") ? "white" : "grey"),
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
        ],
      },
      {
        key: "#manage-alumni",
        label: "Manage Alumni",
        style: {
          background: ((selected === "/add-alumni" || selected === "/update-alumni") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
          color: "grey",
        },
        disabled: true,
        children: [
          {
            key: "/add-alumni",
            label: "Add Alumni",
            style: {
              background: ((selected === "/add-alumni" || selected === "/update-alumni") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
              color: ((selected === "/add-alumni") ? "white" : "grey"),
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
          {
            key: "/update-alumni",
            label: "Update Alumni",
            style: {
              background: ((selected === "/add-alumni" || selected === "/update-alumni") ? "linear-gradient(to right, #272631, #363552)" : "#34334F"),
              color: ((selected === "/update-alumni") ? "white" : "grey"),
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
        ],
      },
      {
        key: "#user-management",
        label: "User Management",
        style: {
          background: "linear-gradient(to right, #272631, #363552)",
          marginBottom: 10,
          color: "white",
        },
        children: [
          {
            key: "/create-user",
            label: "Create User",
            style: {
              background:"linear-gradient(to right, #272631, #363552)",
              color:"grey",
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
          {
            key: "/manage-user",
            label: "Manage User",
            style: {
              background: "linear-gradient(to right, #272631, #363552)",
              color: "white",
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
          {
            key: "/manage-user-role",
            label: "Manage User Role",
            style: {
              background:"linear-gradient(to right, #272631, #363552)",
              color: "grey",
              margin: 0,
              borderRadius: 0,
              width: "auto",
              height: "auto",
            },
            onClick: () => router.push("/"),
          },
        ],
      }
    );

    return menu;
  }
  

  

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            // Sidebar background color:
            // https://github.com/ant-design/ant-design/blob/5.0.0/components/layout/style/index.tsx#L101
            //colorBgHeader: sidebarBackgroundColor,
            //colorItemBgSelected: sidebarMenuSelectedItemBackgroundColor,
          },
        },
      }}
    >
      <Layout className="min-h-screen">
        <Head>
          <meta key="meta-charset" charSet="utf-8" />
          <meta
            key="meta-viewport"
            name="viewport"
            content="width=device-width, initial-scale=1"
          />
          <link key="favicon" rel="icon" href="/favicon.ico" />
        </Head>

        <Sider width={210} className="bg-[#34334F]  hidden lg:block">
          <div className="h-20 m-4 text-white bg-[#34334F]">
            <img
              className="h-20 text-white bg-[#34334F]"
              src="/image/logoPrasMul.png"
              alt="Logo"
            />
          </div>
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L48
                  // colorItemBg: sidebarBackgroundColor,
                  //colorBgHeader: sidebarBackgroundColor,
                  // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L133
                  //colorItemBgSelected: sidebarMenuSelectedItemBackgroundColor,
                },
              },
            }}
          >
            <Menu
              theme="dark"
              mode="inline"
              items={getMenu()}
              onSelect={(e) => setSelected(e.key)}
              style={{ background: "#34334F" }}
            />
          </ConfigProvider>
          <div style={{ minHeight: "70vh", position: "relative" }}>
  {/* Konten lainnya */}

  <h1 style={{ position: "absolute", bottom: 10, fontSize:"10px",textAlign: "center", background: "#34334F", color: "white", width: "100%", }}>
    ©Prasetiya Mulya Alumni Directory 2020</h1>
  
</div>
        </Sider>
        <Drawer
          placement="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          style={{
            background: "#34334F"
          }}
        >
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L48
                  // colorItemBg: sidebarBackgroundColor,
                  //colorBgHeader: sidebarBackgroundColor,
                  // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L133
                  //colorItemBgSelected: sidebarMenuSelectedItemBackgroundColor,
                },
              },
            }}
          >
            <Menu
              theme="dark"
              mode="inline"
              items={getMenu()}
              onSelect={(e) => setSelected(e.key)}
              style={{ background: "#34334F" }}
            />
          </ConfigProvider>
          <h1 style={{ position: "absolute", bottom: 10, fontSize:"10px",textAlign: "left", background: "#34334F", color: "white", width: "80%", }}>
    ©Prasetiya Mulya Alumni Directory 2020</h1>
        </Drawer>

        {/* sidenav kedua */}
        <Layout className="bg-[#F0F5FB]">
          <div className="bg-[#34334F] grid grid-cols-3 lg:hidden px-8 py-4 items-center">
            <div>
              <Button onClick={() => setDrawerOpen(true)} type="primary">
                <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
              </Button>
            </div>
            <div className="h-15 mt-2 text-white bg-[#34334F]">
              <img
                className="h-20 text-white bg-[#34334F]"
                src="image/logoPrasMul.png"
                alt="Logo"
              />
            </div>
            
          </div>
          <Content className="m-5 p-8 bg-[#F0F5FB] ">{children}</Content>
        </Layout>
      </Layout>
    </ConfigProvider>
    
  );
};

export const WithDefaultLayout = (page: React.ReactElement) => (
  <DefaultLayout>{page}</DefaultLayout>
);